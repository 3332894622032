import type { League } from '@prisma/client';
import { Form, NavLink } from '@remix-run/react';
import styles from './styles.module.css';
import ConfirmationButton from '~/components/ConfirmationButton';
import type { LeagueMembership } from '~/models/leagueMembership.server';
import { useCallback, useRef, useState } from 'react';
import useClickOutside from '~/hooks/useClickOutside';
import clsx from 'clsx';
import Link from '~/components/UIKit/Link';

export const LEAVE_LEAGUE_INTENT = 'leaveLeague';

interface Props {
  league: Pick<League, 'id' | 'name'>;
  role: LeagueMembership['role'];
  menuId: string;
  onSelect: () => void;
}

function LeagueListItem({ league, role, menuId, onSelect }: Props) {
  const rootRef = useRef<HTMLLIElement>(null);
  const toggleRef = useRef<HTMLInputElement>(null);
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const onToggleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setDropdownOpen(e.target.checked);
  }, []);

  useClickOutside(rootRef, useCallback(() => {
    if (toggleRef.current?.checked) {
      toggleRef.current.checked = false;
      setDropdownOpen(false);
    }
  }, []));

  const toggleId = `${menuId}-leagueDropdownToggle-${league.id}`;

  return (
    <li key={league.id} className="relative border-t border-violet-400" ref={rootRef}>
      <NavLink
        className={({ isActive }) =>
        clsx('flex flex-row p-4 pr-2 mr-14 text-xl hover:underline', { 'font-bold': isActive })}

        to={`/leagues/${league.id}`}
        onClick={onSelect}>

        <div className="flex-1 break-word">
          {league.name}
        </div>
      </NavLink>
      <label className={styles.dropdownToggleLabel} htmlFor={toggleId}>{isDropdownOpen ? '×' : '⋮'}</label>
      <input
        id={toggleId}
        className={styles.dropdownToggle}
        type="checkbox"
        onChange={onToggleChange}
        aria-label="Toggle league menu"
        ref={toggleRef} />

      <div className="p-4 pt-0">
        {role !== 'ADMIN' && <Form action="/leagues?index" method="POST" onSubmit={onSelect}>
          <input type="hidden" name="leaveLeagueId" value={league.id} />
          <ConfirmationButton
            name="intent"
            value={LEAVE_LEAGUE_INTENT}
            confirmationLabel="Are you sure?">

            Leave league
          </ConfirmationButton>
        </Form>}
        {role === 'ADMIN' &&
        <Link to={`/leagues/${league.id}/admin`} className="text-blue-500 hover:underline" onClick={onSelect}>
            League admin
          </Link>}

      </div>
    </li>);

}

export default LeagueListItem;